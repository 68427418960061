<template>
  <b-card-actions
    ref="cardAction"
    title="Ürünleri Sırala"
    icon="ShoppingCartIcon"
    :showLoading="show"
    :actionCollapse="false"
    :actionRefresh="true"
    :actionNewAdd="false"
    :collapsed="true"
    @refresh="refreshStop('cardAction')"
    @newAdd="handlerNewAdd($event)"
  >
    <template v-slot:body>
      <draggable v-model="urunler" handle=".draggable-task-handle" @end="handlerResimSiraChange">
        <transition-group type="transition" name="flip-list" class="row" tag="div">
          <b-col cols="12" md="4" v-for="(urun, i) in urunler" :key="urun.k_no">
            <b-card class="draggable-task-handle">
              <v-card-text>
                <div class="d-flex">
                  <strong class="text-primary">{{ i + 1 }} </strong>
                  <p class="pl-1">{{ urun.icerik['tr'].urun_adi | str_limit(40) }}</p>
                </div>
              </v-card-text>
            </b-card>
          </b-col>
        </transition-group>
      </draggable>
    </template>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
export default {
  components: {
    draggable,
    BCardActions,
  },
  computed: {
    ...mapGetters(['getUrunler']),
  },
  data: () => ({
    show: false,
    urunler: [],
    posts: [],
  }),
  created() {
    this.handlerUrunler();
  },
  methods: {
    async handlerUrunler() {
      this.show = true;
      await this.$store.dispatch('urunSiraListele');
      this.urunler = this.getUrunler;
      this.show = false;
    },

    async handlerResimSiraChange() {
      this.show = true;
      this.urunler.map((item, i) => {
        let sira = i + 1;
        this.posts.push({
          k_no: item.k_no,
          sira,
        });
      });
      await this.$store.dispatch('urunSiraGuncelle', { urunler: this.posts }).then((res) => {
        if (res.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Güncelleme`,
              icon: 'Trash2Icon',
              variant: 'success',
              text: `Sira Güncelleme başarılı.`,
            },
          });
        }
        this.show = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  cursor: move;
}
</style>
